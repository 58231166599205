<template>
  <div>
    <!-- Edit dialog -->
    <v-dialog
      persistent
      v-model="showEditDialog"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <membership-maintainer
        @close-dialog="closeEditDialog"
        :membership="activeMembership"
      ></membership-maintainer>
    </v-dialog>

    <!-- Deactivate dialog -->
    <v-dialog
      persistent
      v-model="showDeactivateDialog"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <deactivate-membership
        @close-dialog="closeDeactivateDialog"
        :membership="selectedMembership"
      ></deactivate-membership>
    </v-dialog>

    <v-dialog
      persistent
      v-model="planIsOpen"
      min-width="600"
      max-width="900"
      min-height="600"
      max-height="900"
    >
      <plan-details-maintainer
        :content="selectedMembership"
        @close-dialog="closePlanDialog"
      ></plan-details-maintainer>
    </v-dialog>

    <!-- Clipboard copy snackbar -->
    <v-snackbar v-model="copyContentSnackbar">
      {{ copyContentMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="copyContentSnackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Membresías</h1>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="memberships"
      :options.sync="options"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      :sort-desc.sync="sort"
      :disable-sort="loadingData"
      multi-sort
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50, 500, 999]
      }"
    >
      <template v-slot:top>
        <v-row class="px-5 py-5" style="justify-content: space-between; align-items: center">
          <v-col cols="12" md="6" class="my-0 py-0">
            <v-text-field
              v-model="stringToSearch"
              label="Buscar"
              :disabled="loadingData"
              placeholder="Referencia, mascota, plan..."
              dense
              hide-details
              outlined
              append-icon="mdi-magnify"
              @click:append="searchString"
              @keyup="cleanSearch"
              @keyup.enter="searchString"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="my-0 py-0">
            <v-container class="ma-0 pa-0 mt-3 mt-md-0 d-flex align-center justify-end">
              <div class="d-flex flex-column justify-end align-center mr-3 ml-6">
                <v-select
                  v-model="companySelected"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Descargar membresias activas de:"
                  return-object
                ></v-select>
              </div>
              <v-btn v-if="uniqueUser" color="primary" rounded @click="seeAllMemberships()"
                >Ver todas</v-btn
              >
              <v-btn class="mb-3" color="primary" depressed v-if="!uniqueUser" @click="downloadCsv">
                <v-icon dark> mdi-file-download-outline </v-icon>
                <!-- <span>Usuarios activos</span> -->
                <span>CSV</span>
              </v-btn>
            </v-container>
          </v-col>
          <v-col v-if="errorCsv" class="mr-3 ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end">
            <v-alert dense outlined type="error">Ocurrió un error</v-alert>
          </v-col>
        </v-row>
        <v-row class="px-5 pt-0 pb-5">
          <v-chip-group v-model="search" column multiple class="mx-3">
            <v-chip
              filter
              outlined
              v-for="status in statuses"
              v-longclick="() => longPressChip(status)"
              :key="status.name"
              :color="status.color"
              :disabled="loadingData"
            >
              {{ status.name }}
            </v-chip>
          </v-chip-group>
        </v-row>
      </template>
      <template v-slot:item.user="{ item }">
        <span
          v-if="item.status === 2 && (item.contractUrl == '' || item.contractUrl == null)"
          style="color: red"
          ><strong> * </strong></span
        >
        <span>{{ item.user.name || '' }} {{ item.user.surname || '' }}</span>
      </template>

      <template v-slot:item.pet="{ item }">
        <v-chip
          v-if="item.pet.species === 'cat'"
          small
          color="green"
          class="white--text font-weight-bold"
          ><v-icon left> mdi-cat </v-icon> {{ item.pet.name }}
        </v-chip>
        <v-chip
          v-if="item.pet.species === 'dog'"
          small
          color="orange"
          class="white--text font-weight-bold"
          ><v-icon left> mdi-dog </v-icon>{{ item.pet.name }}</v-chip
        >
      </template>

      <template v-slot:item.plan.name="{ item }">
        <v-col class="ma-0 pa-0 mt-1">
          <!-- <template v-if="item.plan.hasFeatures">
            <features-modal :content="item"></features-modal>
          </template> -->
          <template>
            <span
              v-if="item.plan.supplierPlan != null"
              @click="openPlanDialog(item)"
              class="font-weight-medium"
              style="cursor: pointer"
            >
              {{ item.plan.name
              }}{{
                item.plan.supplierPlan.supplier ? ` (${item.plan.supplierPlan.supplier.name})` : ''
              }}
            </span>
          </template>

          <v-chip-group>
            <v-chip outlined color="primary" x-small class="white--text font-weight-bold">{{
              item.paymentInterval === 1 ? 'Anual' : 'Mensual'
            }}</v-chip>
          </v-chip-group>
        </v-col>
      </template>
      <template v-slot:item.startDate="{ item }">
        <span v-if="item.status !== 3 && item.status !== 5">{{ item.startDate | date }}</span>
        <span v-else>—</span>
      </template>
      <template v-slot:item.finishSupplierGraceDays="{ item }">
        <span v-if="item.finishSupplierGraceDays">{{ item.finishSupplierGraceDays | date }}</span>
        <span v-else>—</span>
      </template>
      <template v-slot:item.status="{ item }">
        <membership-status-chip :status="item.status"></membership-status-chip>
      </template>

      <template v-slot:item.nextInvoiceDate="{ item }">
        <span v-if="item.nextInvoiceDate">{{ item.nextInvoiceDate | date }}</span>
        <span v-else>—</span>
      </template>
      <template v-slot:item.actions="props">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              depressed
              x-small
              fab
              color="green"
              v-bind="attrs"
              v-on="on"
              @click="editMembership(props.item)"
            >
              <v-icon small color="white">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Revisar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="props.item.contractUrl !== null && props.item.contractUrl !== ''"
              class="mx-1"
              x-small
              depressed
              fab
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="showPolicy(props.item.contractUrl)"
            >
              <v-icon small color="white">mdi-text-box-search-outline</v-icon>
            </v-btn>
          </template>
          <span>Ver Contrato</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="props.item.status != 4"
              class="mx-1"
              x-small
              depressed
              fab
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="deactivateMembership(props.item)"
            >
              <v-icon small color="white">mdi-close-octagon</v-icon>
            </v-btn>
          </template>
          <span>Desactivar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="props.item.status == 2"
              class="mx-1"
              x-small
              depressed
              fab
              color="grey"
              v-bind="attrs"
              v-on="on"
              @click="copyContent(props.item)"
            >
              <v-icon small color="white">mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Copiar datos</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <span style="color: red; font-size: 12px"
      ><strong>*</strong> : Este usuario no tiene contrato</span
    >
    <overlay :value="loadingOverlay"></overlay>
  </div>
</template>

<script>
// import moment from 'moment';
import MembershipMaintainer from '../components/MembershipMaintainer';
import MembershipStatusChip from '../components/MembershipStatusChip';
import DeactivateMembership from '../components/DeactivateMembership';
// import FeaturesModal from '../components/FeaturesModal';
import Overlay from '../components/Overlay.vue';
import MembershipsService from '../services/memberships.service';
import DateUtils from '../utils/date.utils';
import MiscUtils from '../utils/misc.utils';
import PlanDetailsMaintainer from '../components/PlanDetailsMaintainer.vue';
import CompaniesService from '../services/companie.service';

export default {
  components: {
    MembershipMaintainer,
    MembershipStatusChip,
    DeactivateMembership,
    Overlay,
    PlanDetailsMaintainer
    // FeaturesModal
  },
  data() {
    return {
      errorCsv: false,
      uniqueUser: false,
      showDialog: false,
      showEditDialog: false,
      showDeactivateDialog: false,
      loadingData: true,
      loadingOverlay: false,
      options: {},
      users: [],
      memberships: [],
      selectedUser: null,
      selectedMembership: null,
      activeMembership: null,
      limit: 10,
      page: 1,
      totalItems: 0,
      sort: [],
      search: [0, 1, 2, 3, 4, 5],
      stringToSearch: null,
      statuses: [
        {
          name: 'Activo',
          value: 2,
          color: 'green'
        },

        {
          name: 'Desactivado',
          value: 4,
          color: 'gray'
        },
        {
          name: 'Pendiente de pago',
          value: 5,
          color: 'orange'
        }
      ],
      headers: [
        {
          text: 'Cliente',
          value: 'user',
          sortable: false
        },
        {
          text: 'Mascota',
          value: 'pet',
          sortable: false
        },
        {
          text: 'Referencia',
          value: 'ref',
          sortable: true
        },
        {
          text: 'Plan',
          value: 'plan.name',
          sortable: false
        },
        {
          text: 'Inicio Plan',
          value: 'startDate',
          sortable: false
        },
        {
          text: 'Fin carencia',
          value: 'finishSupplierGraceDays',
          sortable: false
        },
        // {
        //   text: 'Intervalo',
        //   value: 'paymentInterval',
        //   sortable: false
        // },
        {
          text: 'Próximo pago',
          value: 'nextInvoiceDate',
          sortable: true
        },
        /*{
          text: 'Días gratis',
          value: 'finishTrialDate',
          sortable: true
        },*/
        {
          text: 'Estado',
          value: 'status',
          sortable: true
        },

        {
          text: 'Acciones',
          value: 'actions',
          sortable: false
        }
      ],
      userId: null,
      copyContentSnackbar: false,
      copyContentMessage: '',
      planIsOpen: false,
      companies: [{ id: null, name: 'Todos' }],
      companySelected: { id: null, name: 'Todos' }
    };
  },
  beforeMount() {
    if (this.$route.params.userId) {
      this.userId = this.$route.params.userId;
      this.uniqueUser = true;
    }
  },
  mounted() {
    // this.getMemberships(this.page, this.limit);
    this.getCompanies();
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        let sort;
        if (this.options.sortBy.length !== 0) {
          sort = this.options.sortBy.map(s => {
            const index = this.options.sortBy.indexOf(s);
            return this.options.sortDesc[index] == true ? `-${s}` : s;
          });
        }
        this.getMemberships(this.page, this.limit, this.search, sort);
      },
      deep: true
    },
    search: {
      handler() {
        this.page = 1;
        this.limit = 10;
        this.sort = [];
        this.getMemberships(this.page, this.limit, this.search, this.sort);
      },
      deep: true
    }
  },
  methods: {
    openPlanDialog(membership) {
      if (membership) {
        this.planIsOpen = true;
        this.selectedMembership = membership;
      }
    },
    closePlanDialog() {
      this.planIsOpen = false;
    },
    openDialog(user) {
      this.showDialog = true;
      this.selectedUser = user;
    },
    closeDialog() {
      this.showDialog = false;
      this.selectedUser = null;
      this.getMemberships(this.page, this.limit, this.search, this.sort);
      // this.getUsers(this.page, this.limit);
    },
    closeEditDialog() {
      this.showEditDialog = false;
      this.activeMembership = null;
      this.getMemberships(this.page, this.limit, this.search, this.sort);
    },
    closeDeactivateDialog() {
      this.showDeactivateDialog = false;
      this.selectedMembership = null;
      this.getMemberships(this.page, this.limit, this.search, this.sort);
    },
    async getMemberships(page, limit, query, sort) {
      this.loadingData = true;
      const res = await MembershipsService.getMemberships(
        page,
        limit,
        query,
        sort,
        this.userId,
        this.stringToSearch && this.stringToSearch.length >= 3 ? this.stringToSearch : null
      );
      if (res.status === 'ok') {
        this.memberships = res.data.memberships;
        this.totalItems = res.data.totalItems;
        console.log('this.memberships', this.memberships);
      }
      this.loadingData = false;
    },

    async getCompanies() {
      this.loadingData = true;
      const res = await CompaniesService.getCompanies(1, 100);
      if (res.status === 'ok') {
        this.companies = [...this.companies, ...res.data.companies];
        console.log('this.companies', this.companies);
      }
      this.loadingData = false;
    },

    editMembership(membership) {
      this.showEditDialog = true;
      this.activeMembership = membership;
    },
    deactivateMembership(membership) {
      this.showDeactivateDialog = true;
      this.selectedMembership = membership;
    },
    showPolicy(url) {
      window.open(url);
    },
    getRemainingTrialDaysString(membership) {
      return DateUtils.getRemainingTrialDaysString(membership);
    },
    searchString() {
      if (
        (this.stringToSearch && this.stringToSearch.length >= 3) ||
        !this.stringToSearch ||
        this.stringToSearch.length == 0
      ) {
        this.sort = [];
        this.getMemberships(this.page, this.limit, this.search, this.sort);
      }
    },
    cleanSearch() {
      if (!this.stringToSearch || this.stringToSearch.length == 0) {
        this.sort = [];
        this.getMemberships(this.page, this.limit, this.search, this.sort);
      }
    },
    seeAllMemberships() {
      this.$router.replace({ path: '/' });
      this.userId = null;
      this.uniqueUser = false;
      this.getMemberships(this.page, this.limit, this.search, this.sort);
    },
    longPressChip(status) {
      if (this.search.length == 1 && this.search[0] == status.value) {
        this.search = this.statuses.filter(s => s.value != status.value).map(s => s.value);
      } else {
        this.search = [status.value];
      }
    },
    async downloadCsv() {
      try {
        this.loadingOverlay = true;
        const res = await MembershipsService.getExportedCsv(
          this.page,
          this.limit,
          this.companySelected.id
        );
        if (res.status === 'ok') {
          const blob = res.data.data;
          const fileName = `${Date.now().toString()}_memberships.xlsx`;
          const url = URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.download = fileName;
          a.href = url;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          a = null;
          URL.revokeObjectURL(url);
        } else {
          throw Error();
        }
        this.loadingOverlay = false;
        this.errorCsv = false;
      } catch (err) {
        console.error(err);
        this.errorCsv = true;
        this.loadingOverlay = false;
      }
    },

    copyContent(membership) {
      const user = membership.user;
      const pet = membership.pet;
      const data =
        `Nombre tutor: ${user.name} ${user.surname}\n` +
        `Dirección: ${user.address?.street} ${user.address?.number} ${user.address?.extra}\n` +
        `${user.address?.commune?.name}, ${user.address?.region?.name}\n` +
        `Teléfono contacto: ${user.phone}\n` +
        `Nombre mascota: ${pet.name}\n` +
        `Especie: ${pet.species == 'cat' ? 'Gato' : 'Perro'}\n` +
        `Peso mascota: \n` +
        `Motivo consulta: \n` +
        `Costo cliente: `;
      const copyResult = MiscUtils.copyToClipboard(data);
      this.copyContentSnackbar = true;
      this.copyContentMessage = copyResult
        ? 'Se copiaron los datos al portapapeles'
        : 'Hubo un error al copiar los datos';
    }
  }
};
</script>

<style>
.v-slide-group__content {
  align-items: center !important;
}
</style>
